.records-paper {
  flex-direction: column;
  justify-content: start;
  background: rgb(252, 252, 252);
  margin: 10px 20px;
  height: 780px;
  margin-top: -15px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.records-paper h2 {
  padding: 1vh;
  font-size: xx-large;
  color: rgb(245, 248, 245);
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgb(80, 110, 80);
  display: flex;
}

.records-body {
  display: flex;
  flex-direction: column;
  height: 700px;
  align-items: center;
  justify-content: flex-start;
}

.search-bar {
  margin-top: -25px;
  padding: 10px 0px 10px 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: fit-content;
  align-items: center;
}

.table-cell:hover {
  cursor: pointer;
  color: rgb(173, 173, 20);
}

.record-loading {
  width: 80%;
  padding: 5px;
}

.fetching-section {
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2em;
}
