.footer-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1em;
}
.footer-division {
  justify-content: space-around;
  align-items: flex-start;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.main-details {
  display: flex;
  align-self: center;
  color: white;
}

.developers {
  height: 100%;
  align-self: flex-start;
  text-align: start;
}

.links {
  justify-content: flex-start;
  height: 100%;
  text-align: start;
}

.links a {
  color: rgb(214, 235, 216);
}

.header-buttons {
  background: rgb(51, 107, 62);
  margin: 0 3px;
  padding: 4px 7px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  height: 15px;
  width: 50px;
}

.header-buttons:hover {
  background: rgb(17, 70, 16);
  cursor: pointer;
}

.sidebar-button {
  border-radius: 5px;
  color: white;
  background: rgb(51, 107, 62);
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
  height: 25px;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 2px;
  font-size: 1rem;
  word-wrap: break-word;
  padding: 2px 10px;
  display: flex;
}

.sidebar-button:hover {
  background: rgb(17, 70, 16);
  cursor: pointer;
}
