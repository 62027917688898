.App {
  /* text-align: center; */
}

.grid-container {
  display: grid;
  grid-template-columns: 0.4fr 3fr;
  grid-template-rows: 100px 30px 2fr 1fr;
  height: 110vh;
  width: 100vw;
  text-align: center;
}

/* .grid-container div:nth-child(even) {
  background: rgb(228, 228, 225);
}

.grid-container div:nth-child(odd) {
  background: rgb(135, 145, 136);
} */

.title {
  color: white;
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, rgb(40, 59, 40), green, rgb(40, 59, 40));
}

.header {
  margin-top: -10px;
  color: rgb(255, 255, 255);
  background: linear-gradient(to right, rgb(40, 59, 40), green, rgb(40, 59, 40));
  grid-column: 1 /3;
  grid-row: 2 / 3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.side-bar {
  padding-top: 2px;
  background: rgb(170, 189, 171);
  grid-column: 1 / 1;
  grid-row: 3 / 4;
}

.content {
  background: rgb(219, 231, 220);
  grid-column: 2 / 3;
  grid-row: 3 / 4;
}

.footer {
  color: white;
  background: rgb(135, 145, 136);
  grid-column: 1 / 3;
  grid-row: 4 / 5;
  background: linear-gradient(to right, rgb(40, 59, 40), green, rgb(40, 59, 40));
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
